// Plantilla Ganamas
import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/layout"
import { Container } from "@material-ui/core"
import { Typography } from "@material-ui/core";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles } from "@material-ui/core/styles";
//Models
import { PromotionalTermModel } from "../../components/utils/model/PromotionalTermModel";
//Assets
import KING25 from "./../../assets/Benefits/KING25.png";

const useStyles = makeStyles(theme => ({
    root: {},
    ol: { paddingInlineStart: 0 },
    containerPart: {
        [theme.breakpoints.down("md")]: {
            marginTop: "10em",
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: "7em",
        },
        marginTop: "12em",
    },
    imgSize: {
        [theme.breakpoints.down("xs")]: {
            width: "60%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "70%",
        },
        [theme.breakpoints.down("md")]: {
            width: "80%",
        },
        [theme.breakpoints.down("lg")]: {
            width: "90%",
        },
        [theme.breakpoints.down("xl")]: {
            width: "100%",
        },
        width: "100%",
    },
    boxImg: {
        display: "flex",
        justifyContent: "center",
        margin: "auto",
    },
    liStyle: {
        textAlign: "justify",
        padding: 3,
    },
    scrollableDiv: {
        [theme.breakpoints.down("xs")]: {
            height: "auto", /* Alto del div */
        },
        [theme.breakpoints.down("sm")]: {
            height: "auto", /* Alto del div */
        },
        [theme.breakpoints.down("md")]: {
            height: "auto", /* Alto del div */
        },
        [theme.breakpoints.down("lg")]: {
            height: "700px", /* Alto del div */
        },
        [theme.breakpoints.down("xl")]: {
            height: "800px", /* Alto del div */
        },
        height: "auto", /* Alto del div */
        width: "auto", /* Ancho del div */
        overflow: "auto", /* Habilita scroll cuando el contenido excede las dimensiones */
        '&::-webkit-scrollbar': {
            width: '10px', // Ancho del scrollbar
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1', // Color del track
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888', // Color del scrollbar
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#555', // Color del scrollbar al hover
        },
    }
}));

export default function Benefits() {
    let propertiesLayout = new PromotionalTermModel("King25", KING25, "KING25");
    const classes = useStyles()
    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{propertiesLayout.name.toUpperCase()} | Terminos & Condiciones</title>
            </Helmet>
            <Container className={classes.containerPart}>
                <Box mt={4} py={2}>
                    <Typography variant="h2" component="h2" gutterBottom align="center">
                        <b>Términos y Condiciones de la Campaña Promocional</b>
                    </Typography>
                    <Typography variant="h2" component="h2" gutterBottom align="center">
                        <b>“{propertiesLayout.name.toUpperCase()}”</b>
                    </Typography>
                </Box>
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 6, sm: 6, md: 12 }} sx={{
                    maxHeight: {
                        xs: "auto", // Pantallas pequeñas
                        sm: "auto", // Pantallas medianas
                        md: "700px", // Pantallas grandes
                        lg: "800px", // Pantallas más grandes
                    }, marginBottom: 10
                }}>
                    <Grid item xs={6} sm={6} md={6} sx={{ flexGrow: 1 }}>
                        {" "}
                        <img
                            src={propertiesLayout.linkImage}
                            alt={propertiesLayout.desc}
                            className={classes.imgSize}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} sx={{ flexGrow: 1 }}>
                        <div className={classes.scrollableDiv}>
                        <Typography variant="h4" gutterBottom inline>
                                <b>1. Vigencia de la Campaña</b>
                            </Typography>
                            <Typography
                                variant="body1"
                                gutterBottom
                                inline
                                className={classes.liStyle}
                            >
                                La campaña “KING25” estará vigente desde el 1 de Enero  de 2025 
                                hasta el 31 de marzo de 2025.
                            </Typography>
                            <Typography variant="h4" gutterBottom inline>
                                <b>2. Requisitos para participar</b>
                            </Typography>
                            <Typography
                                variant="body1"
                                gutterBottom
                                inline
                                className={classes.liStyle}
                            >
                                Para acceder al beneficio de esta campaña, los clientes deben cumplir con los
                                siguientes requisitos:
                            </Typography>
                            <Typography variant="body1" gutterBottom inline>
                                <ul>
                                    <li className={classes.liStyle}>
                                        <b>Clientes participantes (Referidores):</b> Clientes activos o con cuentas de reciente apertura
                                        que refieran a cualquier persona que realice la apertura de su cuenta CFL.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Abono de capital(Referidos):</b>  Los clientes referidos, una vez aperturada su cuenta, 
                                        deberán realizar un abono de capital igual o superior a $50,000 MXN en cualquiera de los productos
                                        de inversión que ofrece CFL.
                                    </li>
                                </ul>
                            </Typography>
                            <Typography variant="h4" gutterBottom inline>
                                <b>3. Incentivo</b>
                            </Typography>
                            <Typography
                                variant="body1"
                                gutterBottom
                                inline
                                className={classes.liStyle}
                            >
                                Los clientes (referidores) que cumplan con los requisitos de la campaña podrán obtener los siguientes beneficios:
                            </Typography>
                            <Typography variant="body1" gutterBottom inline>
                                <ul>
                                    <li className={classes.liStyle}>
                                        <b>Tasa de rendimiento anual:</b> Tasa anualizada mínima del 2.5%, sin embargo, 
                                        el cliente podrá alcanzar una tasa anualizada máxima del 13%.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Incremento progresivo de la tasa:</b>   La tasa de rendimiento se ajustará progresivamente
                                        según el número de personas referidas por el cliente.
                                        Cada referido que cumpla con el requisito mínimo de abono de $50,000 MXN, 
                                        le otorgara al referidor un 0.50% de aumento en la tasa de su inversión en el producto “Acceso”, 
                                        permitiendo maximizar el rendimiento hasta un límite del 13% anualizado.

                                    </li>
                                </ul>
                            </Typography>
                            <Typography variant="h4" gutterBottom inline>
                                <b>4. Procedimiento para Acceder a la Campaña</b>
                            </Typography>
                            <Typography
                                variant="body1"
                                gutterBottom
                                inline
                                className={classes.liStyle}
                            >
                                Para beneficiarse de esta campaña, los clientes (referidores) deben asegurar que los nuevos clientes (referidos)
                                sigan los siguientes pasos:
                            </Typography>
                            <Typography variant="body1" gutterBottom inline>
                                <ul>
                                    <li className={classes.liStyle}>
                                        <b>Paso 1:</b> Generar la apertura de su cuenta CFL.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Paso 2:</b> Transferir fondos por un importe igual o superior a $50,000 MXN a su
                                        Cuenta Cero CFL.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Paso 3:</b>Realizar la contratación de cualquier producto de inversión de CFL,
                                        por un importe igual o superior a $50,000 MXN.
                                    </li>
                                </ul>
                            </Typography>
                            <Typography
                                variant="body1"
                                gutterBottom
                                inline
                                className={classes.liStyle}
                            >
                                Es importante resaltar que los beneficios de esta campaña son exclusivos para el
                                cliente <b>(Referidor)</b>.
                            </Typography>

                            <Typography variant="h4" gutterBottom inline>
                                <b>5. Condiciones Generales</b>
                            </Typography>
                            <Typography variant="body1" gutterBottom inline>
                                <ol>
                                    <li className={classes.liStyle}>
                                        <b>Producto aplicable: </b>Esta promoción se aplica únicamente para el producto
                                         <b> “Acceso”</b>.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Capital nuevo:</b>  El capital debe ser nuevo y transferido por parte de la persona referida
                                        (nuevo cliente) entre el 1 de enero de 2025 y el 31 de marzo de 2025.

                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Sin excepciones:</b> No se permitirán contrataciones fuera del plazo de
                                        vigencia.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Tasa sujeta a cambios:</b> La tasa de rendimiento puede cambiar sin aviso
                                        previo según las condiciones del mercado.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Exclusividad de la Campaña:</b> Los fondos ingresados ​​bajo esta promoción
                                        solo se aplican a los términos de esta campaña. Si el cliente desea
                                        participar en otra campaña, deberá cumplir con los términos específicos de
                                        esa campaña adicional.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Vigencia de la Tasa:</b>  La tasa de interés promocional será vigente hasta el 15 de Enero de 2026,
                                        con independencia de la fecha en la que haya adquirido el beneficio de esta campaña el cliente.

                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Plazo de aplicación del beneficio:</b> El beneficio se reflejará en el producto
                                        Acceso en un plazo máximo de 72 horas. Los clientes podrán confirmar el
                                        incremento en su tasa de interés a través de su aplicación móvil o sitio web.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Aceptación de los Términos y Condiciones:</b> Al participar en esta
                                        campaña, los clientes aceptan y se comprometen a cumplir con todos los
                                        términos y condiciones aquí establecidos.
                                    </li>

                                </ol>
                            </Typography>
                            </div>
                    </Grid>
                </Grid>
            </Container>
        </Layout >
    )
}